<template>
  <!--  上面的项目tab-->
  <div style="position: relative;height: 100vh;overflow: hidden;">
    <!--顶部收藏项目列表-->
    <div style="height: 40vh;box-sizing: border-box;padding: 10px 20px;" class="ProjectHeader"
      v-if="collectList.length > 0">
      <el-tabs v-if="collectList.length > 0" style="height: 100%;position: relative;">
        <div style="position: absolute;right: 0;top: -50px;height: 10px;z-index: 2000;">
          <el-radio-group v-model="collectViewstyle" size="mini">
            <el-radio-button label="card" :border="false"><i class="el-icon-menu"
                style="font-size: 16px"></i></el-radio-button>
            <el-radio-button label="list" :border="false"><i class="el-icon-s-operation"
                style="font-size: 16px;font-weight: bold"></i></el-radio-button>
          </el-radio-group>
        </div>
        <el-tab-pane label="收藏项目" name="0" style="position: relative;height: 100%;">
          <div class="Project flex" style="height: 100%;">
            <div v-loading="loading" class="projectContent" style="" v-if="collectViewstyle === 'card'">
              <div class="AllBox" style="overflow-y:auto ;position: relative;height: calc(100% - 40px);">
                <el-card v-for="item in collectList" :key="item.Id" :body-style="{ padding: '0px' }" class="proListCard"
                  shadow="hover" @click.native="handleView(item)" :title="item.ProjectName">
                  <el-image :src="getProjectImg(item)" class="proListImg" fit="cover"></el-image>
                  <div v-if="item.ProjectCreatorId === userInfo.Id" class="delIcon" @click.stop="handleDel(item)">
                    <i class="el-icon-delete"></i>
                  </div>
                  <div v-if="!item.IsCollect" class="star" @click.stop="collectProject(item)"><i
                      class="el-icon-star-off"></i></div>
                  <div v-else class="starOn" @click.stop="collectCancelProject(item)"><i class="el-icon-star-on"></i>
                  </div>
                  <div style="padding: 10px"><span class="proName">{{ item.ProjectName }}</span></div>
                  <div class="probottom clearfix">
                    <span class="footerleft" :title="item.ProjectCreatorRealName">{{ item.ProjectCreatorRealName
                      }}</span>&nbsp;&nbsp;
                    <span class="footerright">{{ item.ModifyDate ? timeDataFormat(item.ModifyDate, "YYYY-MM-DD HH:mm") :
                      ""
                      }}</span>
                  </div>
                </el-card>
              </div>
              <pagination :limit.sync="Collecpagination.PageRows" :page.sync="Collecpagination.PageIndex"
                :total="Collecpagination.total" @pagination="CollecpaginationChange"
                style="text-align: right;line-height: 40px;padding: 0;height: 40px;" />
              <!-- <div style="display: flex; justify-content: flex-end; margin-top: 10px">
                
              </div> -->
            </div>
            <div v-loading="loading" v-if="collectViewstyle === 'list'" class="projectContent">
              <div style="overflow-y:auto ;position: relative;height: calc(100% - 40px);">
                <div v-for=" (item, index) in collectList" :key="index" class="list-item">
                  <div style="display: flex; align-items: center; padding: 5px 0px" @click="handleView(item)">
                    <div style="width: 180px;position: relative;height: 80px;margin-right: 20px;">
                      <el-image :src="getProjectImg(item)" class="list-img" fit="cover"></el-image>
                    </div>
                    <div style="flex: auto;font-size: 18px">{{ item.ProjectName }}</div>
                    <div style="width: 180px;font-size: 18px">{{ item.ProjectCreatorRealName }}</div>
                    <div style="width: 220px;font-size: 18px">
                      {{ item.ModifyDate ? timeDataFormat(item.ModifyDate, "YYYY-MM-DD HH:mm") : "" }}
                    </div>
                    <div style="width: 200px">

                      <span style="margin-right: 20px; cursor: pointer" v-if="!item.IsCollect"
                        @click.stop="collectProject(item)">
                        <el-tooltip content="收藏" placement="top" :open-delay="450">
                          <i class="el-icon-star-off" style="font-size: 26px; color: #000"></i>
                        </el-tooltip>
                      </span>
                      <span style="margin-right: 20px; color: #ffa500; cursor: pointer" v-if="item.IsCollect"
                        @click.stop="collectCancelProject(item)">
                        <el-tooltip content="取消收藏" placement="top" :open-delay="450">
                          <i class="el-icon-star-on" style="font-size: 26px"></i>
                        </el-tooltip>
                      </span>
                      <span @click.stop="handleDel(item)" style="cursor: pointer"
                        v-if="item.ProjectCreatorId === userInfo.Id">
                        <el-tooltip content="删除" placement="top" :open-delay="450">
                          <i class="el-icon-delete" style="font-size: 24px;color: #000000"></i>
                        </el-tooltip>
                      </span>
                    </div>
                  </div>
                  <el-divider></el-divider>
                </div>
              </div>
              <pagination :limit.sync="Collecpagination.PageRows" :page.sync="Collecpagination.PageIndex"
                :total="Collecpagination.total" @pagination="CollecpaginationChange"
                style="text-align: right;line-height: 40px;padding: 0;height: 40px;" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div :style="`height: ${collectList.length > 0 ? '59.5vh' : '99.5vh'};box-sizing: border-box;padding: 10px 20px;`"
      class="projectcenter">
      <!--底部项目列表-【全部项目】-【参与项目】-【我的项目】-->
      <el-tabs style=" height: 100%;" v-model="tabsName">
        <div style="position: absolute;right: 0;top: -50px;height: 10px;z-index: 2000;">
          <el-radio-group v-model="viewstyle" size="mini">
            <el-radio-button label="card" :border="false"><i class="el-icon-menu"
                style="font-size: 16px"></i></el-radio-button>
            <el-radio-button label="list" :border="false"><i class="el-icon-s-operation"
                style="font-size: 16px;font-weight: bold"></i></el-radio-button>
          </el-radio-group>
        </div>
        <el-tab-pane :label="tabKeyName" name="1" style="position: relative;height: 100%;">
          <div class="Project flex" style="height: 100%;">
            <div v-loading="loading" class="projectContent" style="" v-if="viewstyle === 'card'">
              <div style="overflow-y:auto ;position: relative;height: calc(100% - 40px);" class="AllBox">
                <el-card :body-style="{ padding: '0px' }" class="proAddCard" shadow="hover" @click.native="handleAdd()"
                  v-if="this.queryParam.ClassType < 1">
                  <el-image :src="getProjectImg(null)" class="proAddImg" fit="cover"></el-image>
                  <div><span class="proAddTitle">点击新建项目</span></div>
                </el-card>
                <el-card v-for="item in data" :key="item.Id" :body-style="{ padding: '0px' }" class="proListCard"
                  shadow="hover" @click.native="handleView(item)" :title="item.Name || item.ProjectName">
                  <el-image :src="getProjectImg(item)" class="proListImg" fit="cover"></el-image>
                  <div v-if="item.ProjectCreatorId === userInfo.Id || item.CreatorId === userInfo.Id" class="delIcon"
                    @click.stop="handleDel(item)"><i class="el-icon-delete"></i></div>
                  <div v-if="!item.IsCollect" class="star" @click.stop="collectProject(item)"><i
                      class="el-icon-star-off"></i></div>
                  <div v-else class="starOn" @click.stop="collectCancelProject(item)"><i class="el-icon-star-on"></i>
                  </div>
                  <div style="padding: 10px"><span class="proName">{{ item.ProjectName || item.Name }}</span></div>
                  <div class="probottom clearfix">
                    <span class="footerleft" :title="item.ProjectCreatorRealName || item.CreatorRealName">{{
                      item.ProjectCreatorRealName || item.CreatorRealName }}</span>&nbsp;&nbsp;
                    <span class="footerright">{{ item.ModifyDate ? timeDataFormat(item.ModifyDate, "YYYY-MM-DD HH:mm") :
                      ""
                      }}</span>
                  </div>
                </el-card>
              </div>

              <!-- 底部分页 -->
              <pagination :limit.sync="pagination.PageRows" :page.sync="pagination.PageIndex" :total="pagination.total"
                @pagination="currentChange" style="text-align: right;line-height: 40px;padding: 0;height: 40px;" />
            </div>
            <div v-loading="loading" v-if="viewstyle === 'list'" style="width: 100%; padding: 0 20px; margin-top: 20px">
              <div style="overflow-y:auto ;position: relative;height: calc(100% - 40px);">
                <div @click="handleAdd()"
                  style="position: sticky; top: 0;border-bottom: 1px solid #E4E7ED; background: linear-gradient(to right,#ffffff,#F6F6F6,#dddddd,#F6F6F6,#ffffff); z-index: 999"
                  v-if="this.queryParam.ClassType < 1">
                  <div style="display: flex; align-items: center; padding: 5px 0px" class="additem">
                    <div style="width: 180px;position: relative;height: 80px;margin: 0 auto">
                      <el-image :src="getProjectImg(null)" class="list-img" fit="cover"></el-image>
                    </div>
                    <div
                      style="flex: auto;font-size: 36px;letter-spacing: 10px; font-weight: bold; text-align: center;padding:0;color: #9b9999">
                      点击新建项目</div>
                  </div>
                </div>
                <div v-for="(item, index) in data" :key="index" class="list-item">
                  <div style="display: flex; align-items: center; padding: 5px 0px" @click="handleView(item)">
                    <div style="width: 180px;position: relative;height: 80px;margin-right: 20px;">
                      <el-image :src="getProjectImg(item)" class="list-img" fit="cover"></el-image>
                    </div>
                    <div style="flex: auto;font-size: 18px;">{{ item.ProjectName || item.Name }}</div>
                    <div style="width: 180px;font-size: 18px;">{{ item.ProjectCreatorRealName }}</div>
                    <div style="width: 220px;font-size: 18px;">{{ item.ModifyDate ? timeDataFormat(item.ModifyDate,
                      "YYYY-MM-DD HH:mm") : "" }}</div>
                    <div style="width: 200px">
                      <span style="margin-right: 20px; cursor: pointer" v-if="!item.IsCollect"
                        @click.stop="collectProject(item)">
                        <el-tooltip content="收藏" placement="top" :open-delay="450"><i class="el-icon-star-off"
                            style="font-size: 26px; color: #000"></i></el-tooltip>
                      </span>
                      <span style="margin-right: 20px; color: #ffa500; cursor: pointer" v-if="item.IsCollect"
                        @click.stop="collectCancelProject(item)">
                        <el-tooltip content="取消收藏" placement="top" :open-delay="450">
                          <i class="el-icon-star-on" style="font-size: 26px"></i>
                        </el-tooltip>
                      </span>
                      <span @click.stop="handleDel(item)" style="cursor: pointer"
                        v-if="(item.ProjectCreatorId || item.CreatorId) === userInfo.Id">
                        <el-tooltip content="删除" placement="top" :open-delay="450"><i class="el-icon-delete"
                            style="font-size: 26px; color: #000"></i></el-tooltip>
                      </span>
                    </div>
                  </div>
                  <el-divider></el-divider>
                </div>
              </div>
              <!-- 底部分页 -->
              <pagination :limit.sync="pagination.PageRows" :page.sync="pagination.PageIndex" :total="pagination.total"
                @pagination="currentChange" style="text-align: right;line-height: 40px;padding: 0;height: 40px;" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>




  </div>
</template>

<script>
import { mapState } from "vuex";
import tools from "@/utils/tools";
import folderImg from "@/assets/folder.png";
import addfolderImg from "@/assets/Addfolder.png";

export default {
  data() {
    return {
      viewstyle: "card",
      collectViewstyle: "card",
      activeName: "All",
      loading: false,
      data: [],
      pagination: {
        PageIndex: 1,
        PageRows: 20,
        total: 0,
        SortField: "ProjectCreateTime",
        SortType: "desc",
        pageSizeOptions: [10, 30, 50],
      },
      Collecpagination: {
        //收藏列表相关分页数据
        PageIndex: 1,
        PageRows: 20,
        total: 0,
        SortField: "ProjectCreateTime",
        SortType: "desc",
        pageSizeOptions: [10, 30, 50],
      },
      queryParam: {},
      folderImg,
      addfolderImg,
      url: "/Base_ProjectWork/Base_ProjectPartake/GetProjectPartakeList",
      collectList: [],
      tabsName: "1",
      tabKeyName: "全部项目",
    };
  },
  computed: {
    ...mapState(["userInfo", "searchValue"]),
    // 绑定项目图片
    getProjectImg: () => {
      return (item) =>
        item ? (item.ProjectImg ? item.ProjectImg : folderImg) : addfolderImg;
    },
    getSearchResultHight() {
      return Number(document.body.offsetHeight - 200);
    },
  },
  mounted() {
    // 清除任务开启标识
    sessionStorage.removeItem("PatGoOpen");
    sessionStorage.removeItem("AttractTaskOpen");
    sessionStorage.removeItem("RGOpen");
  },
  watch: {
    $route: {
      handler(n) {
        this.$store.commit("setSearchValue", "");
        console.log(n.params.type, '******************')

        this.queryParam = {
          UserId: this.userInfo.Id,
          // this.userInfo.Id || JSON.parse(localStorage.getItem("userInfo")).Id,
          ClassType: 0,
          condition: "ProjectName",
          keyword: this.searchValue,
        };
        console.log(n);
        console.log(this.userInfo.Id)
        console.log(JSON.parse(localStorage.getItem("userInfo")).Id)

        this.activeName = n.params.type;
        if (n.params.type === "third") {
          this.tabKeyName = "我的项目";
        } else if (n.params.type === "second") {
          this.tabKeyName = "参与项目";
        } else {
          this.tabKeyName = "全部项目";
        }

        this.handleClick();
      },
      immediate: true,
    },
    searchValue(value, n) {
      this.queryParam = {
        UserId:
          this.userInfo.Id || JSON.parse(localStorage.getItem("userInfo")).Id,
        ClassType: 0,
        condition: "ProjectName",
        keyword: value,
      };
      this.handleClick();
    },
  },
  methods: {
    CollecpaginationChange(data) {
      this.Collecpagination.PageIndex = data.pageIndex;
      this.Collecpagination.PageRows = data.pageSize;
      this.getDataList();
    },
    getcollectData() {
      this.$http
        .post("/Base_ProjectWork/Base_ProjectCollect/GetProjectCollectList", {
          PageIndex: this.Collecpagination.PageIndex,
          PageRows: this.Collecpagination.PageRows,
          SortField: this.Collecpagination.SortField,
          SortType: this.Collecpagination.SortType,
          Search: { ClassType: 1 },
        })
        .then((res) => {
          if (res.Success) {
            this.collectList = res.Data;
            console.log('收藏项目列表', this.collectList)
            this.Collecpagination.total = res.Total;
          }
        });
    },
    handleDel(obj) {
      this.$confirm("此操作将永久删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Base_ProjectWork/Base_Project/AppDeleteData", [
              obj.ProjectId ? obj.ProjectId : obj.Id,
            ])
            .then((res) => {
              if (res.Success) {
                this.getDataList();
                this.$message.success("操作成功");
              } else this.$message.error(res.Msg);
            });
        })
        .catch(() => {
        });
    },
    currentChange(data) {
      this.pagination.PageIndex = data.pageIndex;
      this.pagination.PageRows = data.pageSize;
      this.getDataList();
    },
    getDataList() {
      this.$http
        .post(this.url, {
          PageIndex: this.pagination.PageIndex,
          PageRows: this.pagination.PageRows,
          SortField: this.pagination.SortField,
          SortType: this.pagination.SortType,
          Search: this.queryParam,
        })
        .then((res) => {
          if (res.Success) {
            this.data = res.Data;
            this.pagination.total = res.Total;
          }
        });
    },
    // 页签切换
    handleClick() {
      this.pagination = {
        PageIndex: 1,
        PageRows: 20,
        total: 0,
        SortField: "ProjectCreateTime",
        SortType: "desc",
      };
      if (this.activeName === "third") {
        this.url = "/Base_ProjectWork/Base_Project/GetProjectList";
        this.queryParam.condition = 'Name'
      } else if (this.activeName === "second") {
        this.queryParam.condition = 'ProjectName'
        this.queryParam.ClassType = 1;
        this.url = "/Base_ProjectWork/Base_ProjectPartake/GetProjectPartakeList";
      } else {
        this.queryParam.ClassType = 0;
        this.queryParam.condition = 'ProjectName'

        this.url = "/Base_ProjectWork/Base_ProjectPartake/GetProjectPartakeList";
      }
      this.getDataList();
      this.getcollectData();
    },
    // 新建项目
    handleAdd() {
      console.log(123456789)
      this.loading = true;
      this.$http
        .post("/Base_ProjectWork/Base_Project/SaveData", {
          Name: this.userInfo.Name + tools.getNowDate().split(" ")[0] + "创建",
        })
        .then((res) => {
          this.loading = false;
          if (res.Success) {
            this.$router.push("/Template?prop=add&id=" + res.Data.Id);
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 查看项目
    handleView(item) {
      let pId = item.ProjectId || item.Id;
      this.$router.push("/Template?prop=view&id=" + pId);
    },
    // 收藏项目
    collectProject(item) {
      console.log(item);
      this.$http
        .post("/Base_ProjectWork/Base_ProjectCollect/SaveData", {
          ProjectId: item.ProjectId || item.Id,
          UserId: item.UserId || this.userInfo.Id,
        })
        .then((res) => {
          if (res.Success) {
            this.$set(item, "IsCollect", true);
            this.getDataList();
            this.getcollectData();
          } else this.$message.error(res.Msg);
        });
    },
    collectCancelProject(item) {
      console.log(this.activeName);
      this.$http
        .post("/Base_ProjectWork/Base_ProjectCollect/DeleteDataByCondition", [
          { Condition: "ProjectId", Keyword: item.ProjectId },
          { Condition: "UserId", Keyword: item.UserId },
        ])
        .then((res) => {
          if (res.Success) {
            // item.IsCollect = false;
            this.$set(item, "IsCollect", false);
            this.getDataList();
            this.getcollectData();
            // this.$message.success("操作成功");
          } else this.$message.error(res.Msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-pagination .el-select .el-input .el-input__inner {
  padding-right: 25px;
  padding-top: 2px;
  border-radius: 3px;
}

::v-deep .el-tabs__item {
  font-weight: bold;
  font-size: 16px;
}

::v-deep .el-tabs__item.is-active {
  color: #000;
}

.flex {
  display: flex;

  span {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.Project {
  width: 100%;
  padding-top: 0px;

  // 主体内容
  .projectContent {
    box-sizing: border-box;
    // padding: 0 10px;
    flex: 1;

    .AllBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      // padding-top: 5px;

      .proAddCard {
        background-color: rgba(211, 211, 211, 0.2);
        padding: 0;
        position: relative;
        width: 220px;
        height: 177px;
        margin: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.6);

        &:hover {
          color: rgba(0, 0, 0, 1);
          background-color: rgba(211, 211, 211, 0.8);
          box-shadow: 5px 5px 5px rgb(38 38 38 / 50%),
            5px 5px 10px rgb(38 38 38 / 30%);
        }

        .proAddImg {
          width: 220px;
          height: 100px;
          display: block;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .proAddTitle {
          display: block;
          text-align: center;
          line-height: 77px;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: 5px;
        }
      }

      .proListCard {
        padding: 0;
        position: relative;
        width: 220px;
        height: 177px;
        margin: 5px;

        &:hover {
          box-shadow: 5px 5px 5px rgb(38 38 38 / 50%),
            5px 5px 10px rgb(38 38 38 / 30%);

          .delIcon {
            opacity: 1;
          }

          .star {
            opacity: 1;
          }
        }

        .proListImg {
          width: 220px;
          height: 100px;
          display: block;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .delIcon {
          display: block;
          background: rgba(255, 0, 0, 0.6);
          width: 30px;
          height: 25px;
          line-height: 22px;
          text-align: center;
          font-size: 20px;
          border-radius: 0 0 0 10%;
          opacity: 0;
          transition: all 100ms linear 0s;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1000;
          color: white;

          // &:hover {
          //   color: white;
          //   background: rgba(255, 0, 0, 1);
          // }
        }

        .star {
          background-color: #ffffff;
          width: 30px;
          height: 25px;
          line-height: 22px;
          text-align: center;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          border-radius: 0 0 10% 0;
          z-index: 1000;
          font-size: 20px;

          &:hover {
            background: #ecf5f9;
            color: #f18d20;
          }
        }

        .starOn {
          background-color: rgba(255, 120, 0, 0.9);
          width: 30px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 0 0 10% 0;
          z-index: 1000;
          font-size: 22px;
          color: #ffffff;

          &:hover {
            background-color: rgba(255, 165, 0, 1);
          }
        }

        .clearfix:before,
        .clearfix:after {
          display: table;
          content: "";
        }

        .clearfix:after {
          clear: both;
        }

        .proName {
          flex: 1;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
          font-weight: bold;
          //color: #007fff;
        }

        .probottom {
          padding: 10px;

          .footerleft {
            float: left;
            font-size: 12px;
            color: #999;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 90px;
          }

          .footerright {
            float: right;
            font-size: 12px;
            color: #999;
          }

          .probutton {
            padding: 0;
            font-size: 12px;
            float: right;
          }
        }
      }
    }
  }
}

.list-img {
  width: 100%;
  height: 80px;
}

:deep .hover-row {
  color: #d1100b;
}

.list-item {
  .el-divider {
    margin: 0;
  }

  :hover {
    color: #d1100b;
  }
}

.additem {
  cursor: pointer;

  .el-divider {
    margin: 0;
  }

  :hover {
    color: #d1100b;
  }
}

.el-icon-delete {
  &:hover {
    color: red !important;
  }
}

.el-icon-star-off {
  &:hover {
    color: #ffa500 !important;
  }
}

.el-icon-star-on {
  &:hover {
    color: #cac1b0 !important;
  }
}

:deep .el-tabs__content {
  overflow: visible;
}

.style-but {
  width: 40px;
  height: 40px;
  color: #ccc;
  font-size: 16px;
  padding: 0;
  border-radius: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: #f0f0f0;
    border-radius: 10px;
  }
}

::v-deep .ProjectHeader {
  .el-tabs__content {
    height: calc(100% - 50px);
    // overflow: hidden;
  }

  .pagination-container {
    .el-pagination {
      display: flex;
      height: 30px;
      margin-top: 10px;
      justify-content: right;
    }
  }
}

::v-deep .projectcenter {
  .el-tabs__content {
    height: calc(100% - 50px);
    // overflow: hidden;
  }

  .pagination-container {
    .el-pagination {
      display: flex;
      height: 30px;
      margin-top: 10px;
      justify-content: right;
    }
  }
}
</style>
